/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'check2-all': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12.354 4.354a.5.5 0 00-.708-.708L5 10.293 1.854 7.146a.5.5 0 10-.708.708l3.5 3.5a.5.5 0 00.708 0zm-4.208 7l-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 01.708.708l-7 7a.5.5 0 01-.708 0"/><path pid="1" d="M5.354 7.146l.896.897-.707.707-.897-.896a.5.5 0 11.708-.708"/>',
    },
});
